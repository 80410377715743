<template>
  <!-- App.vue -->

<v-app>

<!--
  <appbarstart></appbarstart>
  <navileft></navileft>
-->
 
 
      <router-view></router-view>
  
</v-app>
</template>

<script>

export default {
    name: 'App',
    

    components: {
    
    },

    computed: {
        loggedIn: function() {
            return this.$store.state.auth.status.loggedIn;
        },
    
    },  

    data: () => ({
        timeoutInMS: 180000, // 3 minutes -> 3 * 60 * 1000 = 180000
        timeoutId: undefined,
        
    }),

    methods: {
        handleInactive() {
            console.debug("handleInactive logout")
            // Here you want to logout a user and/or ping your token
            // this.$store.dispatch('account/logout', this.$store.state.account.data);
        },

        startTimer() { 
            // setTimeout returns an ID (can be used to start or clear a timer)
            this.timeoutId = setTimeout(this.handleInactive, this.timeoutInMS);
        },

        resetTimer() { 
            clearTimeout(this.timeoutId);
            this.startTimer();
        }        
    },

    watch: {
        // loggedIn(newVal) {
            // // console.log(`Updating from ${oldValue} to ${newValue}`);
            // if(newVal){
            //     console.debug('loggedIn changed add EventListener')
            //     document.addEventListener("keypress", this.resetTimer, false);
            //     document.addEventListener("mousemove", this.resetTimer, false);
            //     document.addEventListener("mousedown", this.resetTimer, false);
            //     document.addEventListener("touchmove", this.resetTimer, false);                
            //     console.debug('loggedIn changed add EventListener')
            // }
            // else{
            //    console.debug('loggedIn changed remove EventListener')
            //     document.removeEventListener("keypress", this.resetTimer, false);
            //     document.removeEventListener("mousemove", this.resetTimer, false);
            //     document.removeEventListener("mousedown", this.resetTimer, false);
            //     document.removeEventListener("touchmove", this.resetTimer, false); 
            //     this.$router.push('/');
            // }
            //this.setData()
        },
    // },

    created() {
        console.debug("created")
  
    },
    
    


};
</script>

