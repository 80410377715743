import Vue from 'vue'
// import * as VueGoogleMaps from 'vue2-google-maps'

// import ECharts from 'vue-echarts'
// import 'echarts/lib/chart/line'
// import 'echarts/lib/chart/bar'
// import 'echarts/lib/component/title'
// import 'echarts/lib/component/legend'
// import 'echarts/lib/component/tooltip'
// import 'echarts/lib/component/grid'
// import 'echarts/lib/component/toolbox'
import * as echarts from 'echarts';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart,LineChart,ScatterChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
// import { transform } from 'echarts-stat'
// console.debug("test-----------------", transform.regression)


// import { transform1 } from './utils'

// console.debug("test-----------------", transform1.regression)
const math = require("./utils/math")
var FORMULA_DIMENSION = 2;
echarts.registerTransform(
  {

    type: 'ecStat:powerregression',

    transform: function transform(params) {
        var upstream = params.upstream;
        var config = params.config || {};

        var result = math.power(upstream.cloneRawData(),{precision: 3});
        var points = result.points;

        var formulaOn = config.formulaOn;
        if (formulaOn == null) {
            formulaOn = 'end';
        }

        var dimensions;
        if (formulaOn !== 'none') {
            for (var i = 0; i < points.length; i++) {
                points[i][FORMULA_DIMENSION] =
                (
                    (formulaOn === 'start' && i === 0)
                    || (formulaOn === 'all')
                    || (formulaOn === 'end' && i === points.length - 1)
                ) ? result.expression : '';
            }
            dimensions = upstream.cloneAllDimensionInfo();
            dimensions[FORMULA_DIMENSION] = {};
        }

        return [{
            dimensions:dimensions,
            data: points
        }];
    }
}



)


import ECharts from 'vue-echarts';

use([
  CanvasRenderer,
  PieChart,
  LineChart,
  ScatterChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);



import App from './App.vue'
import router from './router/index'
import store from './store'
import vuetify from './plugins/vuetify'
import GmapVue from 'gmap-vue'

require('dotenv').config();

Vue.config.productionTip = false
//Vue.use(VueGoogleMaps);
Vue.use(GmapVue)
Vue.component('v-chart', ECharts)
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
