import AuthService from '../services/auth.service';


function getUserFromSession(){
    const res = JSON.parse(sessionStorage.getItem('user'))
    console.debug('getUserFromSession', res)
    return res
}

const user = getUserFromSession()
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                user => {
                    if (user.accessToken) {
                        
                        commit('loginSuccess', user);
                    }
                    
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },

        logout({ commit }) {
            // AuthService.logout();            
            commit('logout');

        },

        changeLastCompany({ commit }, uid) {
            // AuthService.logout();
            commit('changeLastCompany', uid);
            
        },


        register({ commit }, user) {
            return AuthService.register(user).then(
                response => {
                commit('registerSuccess');
                return Promise.resolve(response.data);
                },
                error => {
                commit('registerFailure');
                return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        loginSuccess(state, user) {
            console.debug('loginSuccess', user);
            state.status.loggedIn = true;
            state.user = user;
            sessionStorage.setItem('user', JSON.stringify(user));
            // console.debug('loginSuccess state', state);
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            AuthService.logout(state.user)
            state.status.loggedIn = false;
            state.user = null;
            sessionStorage.removeItem('user');
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        changeLastCompany(state, uid){
            state.user.last_company = uid
            sessionStorage.setItem('user', JSON.stringify(state.user));
        }
    },

};