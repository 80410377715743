
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import { auth } from "./auth.module";
import { app } from "./app.module";
import { management } from "./management.module";

const store = new Vuex.Store({
    modules: {
        auth,
        app,
        management
    },
});

export default store;

