
/**
* Determine the coefficient of determination (r^2) of a fit from the observations
* and predictions.
*
* @param {Array<Array<number>>} data - Pairs of observed x-y values
* @param {Array<Array<number>>} results - Pairs of observed predicted x-y values
*
* @return {number} - The r^2 value, or NaN if one cannot be calculated.
*/
const determinationCoefficient = function(data, results) {
    const predictions = [];
    const observations = [];
  
    data.forEach((d, i) => {
      if (d[1] !== null) {
        observations.push(d);
        predictions.push(results[i]);
      }
    });
  
    const sum = observations.reduce((a, observation) => a + observation[1], 0);
    const mean = sum / observations.length;
  
    const ssyy = observations.reduce((a, observation) => {
      const difference = observation[1] - mean;
      return a + (difference * difference);
    }, 0);
  
    const sse = observations.reduce((accum, observation, index) => {
      const prediction = predictions[index];
      const residual = observation[1] - prediction[1];
      return accum + (residual * residual);
    }, 0);
  
    return 1 - (sse / ssyy);
  }

/**
* Round a number to a precision, specificed in number of decimal places
*
* @param {number} number - The number to round
* @param {number} precision - The number of decimal places to round to:
*                             > 0 means decimals, < 0 means powers of 10
*
*
* @return {numbr} - The number, rounded
*/
const round = function(number, precision) {
    const factor = 10 ** precision;
    return Math.round(number * factor) / factor;
}

// const yFromPowerReg = function(x,coeffA,coeffB ){return coeffA * x **coeffB}


const power = function(data, options) {
    const sum = [0, 0, 0, 0, 0];
    const len = data.length;

    for (let n = 0; n < len; n++) {
      if (data[n][1] !== null) {
        sum[0] += Math.log(data[n][0]);
        sum[1] += Math.log(data[n][1]) * Math.log(data[n][0]);
        sum[2] += Math.log(data[n][1]);
        sum[3] += (Math.log(data[n][0]) ** 2);
      }
    }

    const b = ((len * sum[1]) - (sum[0] * sum[2])) / ((len * sum[3]) - (sum[0] ** 2));
    const a = ((sum[2] - (b * sum[0])) / len);
    const coeffA = round(Math.exp(a), options.precision);
    const coeffB = round(b, options.precision);

    const predict = x => ([
      round(x, options.precision),
      round(round(coeffA * (x ** coeffB), options.precision), options.precision),
    ]);

    const points = data.map(point => predict(point[0]));
    const res = {
        type:"power",
        points,
        predict,
        equation: [coeffA, coeffB],
        // func:"y = " + coeffA + "*x**" + coeffB,
        funcGetY: function(x){return coeffA *x**coeffB},
        funcGetX: function(y){return Math.exp(Math.log(y/coeffA)/coeffB)},
        expression: `y = ${coeffA}x^${coeffB}`,
        r2: round(determinationCoefficient(data, points), options.precision),
    }

    return res
  }


  const out = {
    determinationCoefficient: determinationCoefficient,
    round: round,
    power: power
};
  
module.exports = out;