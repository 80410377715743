import axios from 'axios';
const apiconfig = require("../config/api.config");
const API_URL = apiconfig.API_URL + "/api/auth";

class AuthService {
    login(user) {
        return axios
        .post(API_URL + '/signin', {
            username: user.username,
            password: user.password
        })
        .then(response => {
            // if (response.data.accessToken) {
            //     sessionStorage.setItem('user', JSON.stringify(response.data.user));
            // }
            console.log('AuthService login',response.data)
            return response.data;
        });    
    }

    logout(user){
        const header = {
            headers: {"x-access-token":user.accessToken}
        }

        console.debug('header', header)
        return axios
        .post(API_URL + '/signout', user, header)
        .then(response => {
            return response.data;
        });
    }

    register(user) {
        return axios.post(API_URL + '/signup', {
        username: user.username,
        email: user.email,
        password: user.password,
        password_repeat: user.password

        });
    }
}

export default new AuthService();