import axios from 'axios';
import authHeader from './auth-header';
const apiconfig = require("../config/api.config");
const API_URL = apiconfig.API_URL;


class AxiosService {

    get(path,param){
        const config = {
            params: param,
            headers: authHeader(),
        };
        return axios.get(API_URL + "/" +path, config).then(response => {
             console.log('get',response)
            return response;
        })
  
    }

    post(path,body){
 
        return axios.post(API_URL + "/" + path, body, { headers: authHeader() }).then(response => {
             console.log('post',response)
            return response;
        })
  
    }

    put(path,body){
 
        return axios.put(API_URL + "/" +path, body, { headers: authHeader() }).then(response => {
             console.log('put',response)
            return response;
        })
  
    }

    patch(path,body){

       return axios.patch(API_URL + "/" +path, body, { headers: authHeader() }).then(response => {
            console.log('patch',response)
           return response;
       })        
    }


    delete(path,param){
 
        const config = {
            headers: authHeader(),
            data: param
        }

        return axios.delete(API_URL + "/" + path, config).then(response => {
            console.log('delete',response)
            return response;
        })
  
    }        


}

export default new AxiosService();