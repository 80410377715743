import Vue from 'vue'
import AxiosService from "../services/axios.service";

const initialState = {
    menu:
    [
        { title: 'Home', icon: 'mdi-home', actions:[] }
    ]
    
}

const updateObjProp = (obj, value, propPath) => {
    const [head, ...rest] = propPath.split('/');

    !rest.length
        ? obj[head] = value
        : updateObjProp(obj[head], value, rest.join('/'));
} 

const getObjProp = (obj, propPath) => {
    const [head, ...rest] = propPath.split('/');

    if(!rest.length)
        return obj[head]
        
    return getObjProp(obj[head], rest.join('/'));
} 

export const management = {
    
    namespaced: true,
    state: initialState,
    getters: {},

    actions:{

        clearData({ commit }) {
            // AuthService.logout();
            commit('clearData');
        },

        getApiSetStore({commit},payload){
            console.debug('getApi', payload)
            return new Promise((resolve, reject) => {
                AxiosService.get(payload.path, payload.param).then(
                    (response) => {
                        try {
                            console.debug('getApi', response)
                            payload.data = response.data
                            let mutation = payload.mutation ? payload.mutation: 'setStore'                            
                            commit(mutation, payload)
                            resolve(response)
                        }catch (error) {
                            const err = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                            console.log(err)
                            reject(err)
                        }
                         
                    },
                    (error) => {
                        const err = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                        console.log(err)
                        reject(err)
                    }
                )
            })             
        },

        putApiSetStore({commit},payload){
            console.debug('putApiSetStore',payload)            
            return new Promise((resolve, reject) => {
                AxiosService.put(payload.path, payload.param).then(
                    (response) => {
                        try {
                            console.debug('putApiSetStore', response)
                            payload.data = response.data
                            let mutation = payload.mutation ? payload.mutation: 'updateStore'                            
                            commit(mutation, payload)
                            resolve(response)
                        } catch (error) {
                            const err = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                            console.log(err)
                            reject(err)
                        }
                        
                    },
                    (error) => {
                        const err = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                        console.log(err)
                        reject(err)
                    }
                ) 
            })            
        },


        postApiSetStore({commit},payload){
            console.debug('postApiSetStore',payload)            
            return new Promise((resolve, reject) => {
                AxiosService.post(payload.path, payload.param).then(
                    (response) => {
                        try {
                            console.debug('postApiSetStore', response)
                            payload.data = response.data
                            let mutation = payload.mutation ? payload.mutation: 'addStore'                            
                            commit(mutation, payload)
                            resolve(response)
                        } catch (error) {
                            const err = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                            console.log(err)
                            reject(err)
                        }
                        
                    },
                    (error) => {
                        const err = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                        console.log(err)
                        reject(err)
                    }
                ) 
            })            
        },


        patchApiSetStore({commit},payload){
            console.debug('patchApiSetStore',payload)            
            return new Promise((resolve, reject) => {
                AxiosService.patch(payload.path, payload.param).then(
                    (response) => {
                        try {
                            console.debug('patchApiSetStore', response)
                            payload.data = response.data
                            let mutation = payload.mutation ? payload.mutation: 'updateStore'                            
                            commit(mutation, payload)
                            resolve(response)
                        } catch (error) {
                            const err = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                            console.log(err)
                            reject(err)
                        }
                        
                    },
                    (error) => {
                        const err = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                        console.log(err)
                        reject(err)
                    }
                ) 
            })            
        },   

        deleteApiSetStore({commit},payload){
            console.debug('deleteApiSetStore',payload)            
            return new Promise((resolve, reject) => {
                AxiosService.delete(payload.path, payload.param).then(
                    (response) => {
                        try {
                            console.debug('deleteApiSetStore', response, commit)
                            payload.data = response.data
                            const mutation = payload.mutation ? payload.mutation: 'deleteStore'                            
                            commit(mutation, payload)
                            resolve(response)
                        } catch (error) {
                            const err = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                            console.log(err)
                            reject(err)
                        }
                        
                    },
                    (error) => {
                        const err = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                        console.log(err)
                        reject(err)
                    }
                ) 
            })            
        },        

 
 
    },

    mutations: {

        setStore(state, payload){
            try {
                console.debug('setStore', state, payload)
                if(payload.data[payload.param.store])
                    Vue.set(state, payload.param.store, payload.data[payload.param.store])
                else
                    Vue.set(state, payload.param.store, payload.data)
     
            } catch (error) {
                console.log((error.response && error.response.data && error.response.data.message) || error.message || error.toString())               
            }
        },

        addStore(state, payload){
            try {
                console.debug('addStore', state, payload)
                let items = getObjProp(state, payload.param.store)
                let data = payload.data
 
                if(Array.isArray(data)){
                    for (const iterator of data) {
                        items.push(iterator)
                    }
                }
                else
                    items.push(data) 
               
      
            } catch (error) {
                console.log((error.response && error.response.data && error.response.data.message) || error.message || error.toString())               
            }
        },

        updateStore(state, payload){
            try {
                console.debug('updateStore', state, payload)
                let data = payload.data

                let items = getObjProp(state, payload.param.store)
                let idx =  items.findIndex( item => item.id == data.id ) 
 
                items[idx] =  data

                const res = JSON.parse(JSON.stringify(items))
                updateObjProp(state, res, payload.param.store)
                
     
            } catch (error) {
                console.log((error.response && error.response.data && error.response.data.message) || error.message || error.toString())               
            }
        },

        deleteStore(state, payload){
            try {
                console.debug('deleteStore', state, payload)
                
                let items = getObjProp(state, payload.param.store)
                let idx =  items.findIndex( item => item.id == payload.param.id ) 

                if(idx >= 0)
                    items.splice(idx,1)  
             
     
            } catch (error) {
                console.log((error.response && error.response.data && error.response.data.message) || error.message || error.toString())               
            }
        }, 
        
        clearStore(state){
            try {
                console.debug('clearStore', state)
                state = initialState
     
            } catch (error) {
                console.log((error.response && error.response.data && error.response.data.message) || error.message || error.toString())               
            }
        },         

    }    

};

