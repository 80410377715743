import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import('../views/Home')
const Management = () => import('../views/Management')
const AccountViewer = () => import('../views/management/accounts/accountsviewer')
const CompaniesViewer = () => import('../views/management/companies/companyviewer')
const ContactsViewer = () => import('../views/management/contacts/contactsviewer')


const LoadCompany = () => import('../views/LoadCompany')
const Company = () => import('../views/Company')
const FieldsViewer = () => import('../views/company/fieldsviewer')
const FieldViewer = () => import('../views/company/fieldviewer')
const SmartDevicesViewer = () => import('../views/company/smartdevicesviewer')
const NozzlesFlowTables = () => import('../views/company/nozzlesFlowTables')
const PlantprotectionPlan = () => import('../views/company/protectionplan')
const PlantprotectionCalc = () => import('../views/company/plantprotectionCalc')
const PlantprotectionPlanAddEdit = () => import('../views/company/plantprotectionPlanAddEdit')
const NotFoundPage = () => import('../views/errorPages/PageNotFound')

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}


//management/accounts/view
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/company/:uid/load',
        name: 'LoadCompany',
        component: LoadCompany,
    },    
    {
        path: '/company/:uid',
        name: 'Company',
        component: Company,
        children: [
           {
                path: '/company/fields/view',
                alias: '/company/fields',
                name: 'FieldsViewer',
                component: FieldsViewer
            },
            {
                path: '/company/smartdevices/view',
                alias: '/company/smartdevices',
                name: 'SmartDevicesViewer',
                component: SmartDevicesViewer
            },
            {

                path: '/company/:uid/field/:fieldid',
                name: 'Field',
                component: FieldViewer
            },
            {
                path: '/company/plantprotection/protectionplan',
                name: 'PlantprotectionPlan',
                component: PlantprotectionPlan
            },  
            {
                path: '/company/plantprotection/protectionplan/add',
                name: 'PlantprotectionPlanAddEdit',
                component: PlantprotectionPlanAddEdit
                
            },
            {
                path: '/company/plantprotection/calc',
                name: 'PlantprotectionCalc',
                component: PlantprotectionCalc
            },   
            {
                path: '/company/plantprotection/nozzlestables',
                name: 'NozzlesFlowTables',
                component: NozzlesFlowTables
            },         
            {
                path: '/company/*',
                name: 'CompanyPageNotFound',
                component: NotFoundPage
            },

        ]
    },
    {
        path: '/management',
        name: 'Management',
        component: Management,
        children: [
            {
                path: '/management/accounts',
                name: 'Management',
                component: AccountViewer
            },
            {
                path: '/management/companies',
                name: 'Management',
                component: CompaniesViewer
            },
            {
                path: '/management/contacts',
                name: 'Contacts',
                component: ContactsViewer
            },
        ]
    },

    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // },
    {
        path: '*',
        name: '*',
        component: NotFoundPage
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
